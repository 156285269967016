import { render, staticRenderFns } from "./ContactoView.vue?vue&type=template&id=0302cf45&scoped=true"
import script from "./ContactoView.vue?vue&type=script&lang=js"
export * from "./ContactoView.vue?vue&type=script&lang=js"
import style0 from "./ContactoView.vue?vue&type=style&index=0&id=0302cf45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0302cf45",
  null
  
)

export default component.exports