import { render, staticRenderFns } from "./PoliticaPrivacidad.vue?vue&type=template&id=276770e1&scoped=true"
import script from "./PoliticaPrivacidad.vue?vue&type=script&lang=js"
export * from "./PoliticaPrivacidad.vue?vue&type=script&lang=js"
import style0 from "./PoliticaPrivacidad.vue?vue&type=style&index=0&id=276770e1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276770e1",
  null
  
)

export default component.exports